import React, { Fragment } from "react"

import { useTheme } from "@material-ui/core/styles"
import { Container, Box, Typography, Grid, Button } from "@material-ui/core"
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"

import Lightbox from "../components/lightbox"

const Section = ({
  background,
  fluid,
  title,
  texts,
  buttonText,
  buttonUrl,
}) => {
  const theme = useTheme()

  return (
    <Box
      paddingY={8}
      bgcolor={
        background === "primary"
          ? "primary.dark"
          : background === "secondary"
          ? "secondary.dark"
          : background === "light"
          ? "text.primary"
          : ""
      }
      color={
        background === "primary"
          ? "primary.contrastText"
          : background === "secondary"
          ? "primary.contrastText"
          : background === "light"
          ? "secondary.contrastText"
          : ""
      }
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs={12} md={5}>
            <Lightbox fluid={fluid} />
          </Grid>
          <Grid item xs={12} md={7}>
            {title && (
              <Typography paragraph variant="h5">
                {title}
              </Typography>
            )}
            <Typography component="div">
              {texts &&
                texts.map((text, index) => (
                  <Box
                    key={index}
                    paddingBottom={2}
                    fontWeight="fontWeightLight"
                    dangerouslySetInnerHTML={{
                      __html: text,
                    }}
                  />
                ))}
            </Typography>
            {buttonText && buttonUrl && (
              <Fragment>
                <Typography>
                  <Box component="span" fontWeight="fontWeightLight">
                    En savoir plus :
                  </Box>
                  <Button
                    variant="outlined"
                    color="inherit"
                    size="small"
                    startIcon={<OpenInNewIcon />}
                    style={{ marginLeft: theme.spacing(1) }}
                    href={buttonUrl}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    {buttonText}
                  </Button>
                </Typography>
              </Fragment>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default Section
