import React, { Fragment, useState } from "react"
import Img from "gatsby-image"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import { Dialog, Fab, Box } from "@material-ui/core"
import { Close as CloseIcon, ZoomOutMap as ZoomIcon } from "@material-ui/icons"

const useStyles = makeStyles({
  button: {
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  image: {
    transition: "transform .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  overlay: {
    opacity: 0,
    transition: "opacity .2s ease-in-out",
    "&:hover": {
      opacity: 1,
    },
  },
})

const Lightbox = ({ fluid }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <a href="/#" onClick={openModal}>
        <Box overflow="hidden" style={{ borderRadius: "4px" }}>
          <Box position="relative" className={classes.image}>
            <Img
              fluid={{
                ...fluid,
                aspectRatio: 16 / 9,
              }}
            />
            <Box
              position="absolute"
              top={0}
              right={0}
              bottom={0}
              left={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.overlay}
            >
              <Fab size="large" className={classes.button} onClick={openModal}>
                <ZoomIcon fontSize="large" />
              </Fab>
            </Box>
          </Box>
        </Box>
      </a>

      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth={"xl"}
        style={{ width: "100%", height: "100vh" }}
      >
        <Img fluid={fluid} />
        <Fab
          size="small"
          className={classes.button}
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
          }}
          onClick={closeModal}
        >
          <CloseIcon />
        </Fab>
      </Dialog>
    </Fragment>
  )
}

export default Lightbox
