import React, { Fragment, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Container,
  Box,
  Typography,
  Grid,
  Button,
  Fab,
  Dialog,
} from "@material-ui/core"
import { PlayArrow as PlayIcon, Close as CloseIcon } from "@material-ui/icons"

import Section from "../components/section"
import Lightbox from "../components/lightbox"

const useStyles = makeStyles({
  button: {
    zIndex: 1,
    color: "white",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    "&:hover": {
      backgroundColor: "black",
    },
  },
  image: {
    transition: "transform .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
})

const IndexPage = () => {
  const classes = useStyles()
  const theme = useTheme()

  const data = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      video: file(relativePath: { eq: "video.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      berger: file(relativePath: { eq: "berger.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      confiance: file(relativePath: { eq: "confiance.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      formation: file(relativePath: { eq: "formation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      predation: file(relativePath: { eq: "predation.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      initiative: file(relativePath: { eq: "initiative.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      frontalier: file(relativePath: { eq: "frontalier.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      cartographie: file(relativePath: { eq: "cartographie.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      recevoir: file(relativePath: { eq: "recevoir.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      envoyer: file(relativePath: { eq: "envoyer.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      agent: file(relativePath: { eq: "agent.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      schema: file(relativePath: { eq: "schema.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const [open, setOpen] = useState(false)

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <Fragment>
      <Box position="relative">
        <BackgroundImage
          Tag="section"
          fluid={data.banner.childImageSharp.fluid}
          style={{ width: "100%", minHeight: "50vh" }}
        >
          <Box
            position="absolute"
            top={0}
            right={0}
            bottom={0}
            left={0}
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{
              backgroundColor: theme.palette.primary.dark,
              opacity: 0.8,
              color: "white",
            }}
          >
            <Box paddingY={8}>
              <Container maxWidth="lg">
                <Typography
                  variant="h5"
                  align="center"
                  style={{ textTransform: "uppercase" }}
                >
                  Outil d'aide à la gestion en temps réel de la protection des
                  troupeaux
                </Typography>
              </Container>
            </Box>
          </Box>
        </BackgroundImage>
      </Box>

      <Box paddingY={8}>
        <Container maxWidth="lg">
          <Grid container alignItems="center" spacing={4}>
            <Grid item xs={12} md={5} align="center">
              <Typography paragraph variant="h5">
                <Box component="span" fontWeight="fontWeightLight">
                  {`Proxiloup est une application cartographique collaborative,
                  permettant d'informer un réseau d'éleveurs et de bergers par SMS en
                  cas de présence suspectée de prédateurs sur le territoire.`}
                </Box>
              </Typography>
              <Box margin={4}>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  startIcon={<PlayIcon />}
                  onClick={openModal}
                >
                  Voir la vidéo
                </Button>
              </Box>
            </Grid>
            <Grid item xs={12} md={7}>
              <a href="/#" onClick={openModal}>
                <Box overflow="hidden" style={{ borderRadius: "4px" }}>
                  <Box position="relative" className={classes.image}>
                    <Img
                      fluid={{
                        ...data.video.childImageSharp.fluid,
                        aspectRatio: 16 / 9,
                      }}
                    />
                    <Box
                      position="absolute"
                      top={0}
                      right={0}
                      bottom={0}
                      left={0}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Fab
                        size="large"
                        className={classes.button}
                        onClick={openModal}
                      >
                        <PlayIcon fontSize="large" />
                      </Fab>
                    </Box>
                  </Box>
                </Box>
              </a>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Dialog
        open={open}
        onClose={closeModal}
        fullWidth
        maxWidth={"xl"}
        style={{ width: "100%", height: "100vh" }}
      >
        <Fab
          size="large"
          style={{
            position: "absolute",
            right: theme.spacing(1),
            top: theme.spacing(1),
            // color: theme.palette.grey[500],
          }}
          onClick={closeModal}
        >
          <CloseIcon fontSize="large" />
        </Fab>

        <iframe
          title="YouTube Video"
          style={{ height: "100vh" }}
          src={`https://www.youtube-nocookie.com/embed/ske-pcgJS3s?autoplay=1&modestbranding=1&rel=0&showinfo=0&autohide=1`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Dialog>

      <Section
        background="secondary"
        fluid={data.berger.childImageSharp.fluid}
        title="Un outil d'information pour les éleveurs et les bergers"
        texts={[
          `
          Proxiloup est un <b>outil créé pour les éleveurs et les bergers</b> qui souhaitent obtenir des informations sur la présence possible de prédateurs dans leur environnement immédiat afin de pouvoir <b>adapter au quotidien le niveau de protection</b> de leurs troupeaux.
          `,
          `
          Ce dispositif permet une communication rapide par SMS lorsque <b>des prédateurs ou des évènements en lien possible avec des prédateurs</b> sont signalés dans l'environnement proche du bétail.
          `,
          `
          Les fonctionnalités proposées sont issues des résultats de recherches de l'Institut IPRA (FJML) et d’un travail continu de <b>concertation avec les éleveurs et les bergers</b>, ce qui en fait un <b>service</b> adapté aux besoins des utilisateurs et ajustable aux particularités de chaque territoire.
          `,
        ]}
        buttonText="Site de l'IPRA"
        buttonUrl="https://www.ipra-landry.com"
      />

      <Section
        background="light"
        fluid={data.cartographie.childImageSharp.fluid}
        title="Cartographie collaborative"
        texts={[
          `
          Proxiloup comporte une fonctionnalité de signalement par SMS, mais cette application est avant tout un <b>Système d’Information Géographique</b> (SIG) composé d’un module de <b>cartographie collaborative en ligne</b> (webmapping), où les utilisateurs peuvent partager leurs observations et consulter les données qui les concernent à tout moment.
          `,
        ]}
        buttonText=""
        buttonUrl=""
      />

      <Section
        background="primary"
        fluid={data.recevoir.childImageSharp.fluid}
        title="Recevoir les notifications"
        texts={[
          `
          Les éleveurs inscrits sur cette plateforme peuvent <b>localiser leur troupeau</b> sur une carte et lorsqu’un événement révélant la présence supposée de prédateurs est déclaré, ils reçoivent alors un <b>signalement par SMS</b> qui leur permet ensuite de visualiser les détails sur la carte depuis leur <b>smartphone ou ordinateur</b>.
          `,
          `
          Le SMS contient suffisamment d'information pour que le berger ne possédant ni smartphone ni ordinateur puisse décider des mesures à prendre sur le terrain.
          `,
        ]}
        buttonText=""
        buttonUrl=""
      />

      <Section
        background=""
        fluid={data.agent.childImageSharp.fluid}
        title="Diffusion des données"
        texts={[
          `
          Chaque information publiée sur Proxiloup est traitée par des agents habilités. Ils collectent les données auprès des "contributeurs Proxiloup" et <b>s'assurent de la fiabilité des informations</b> avant de les diffuser au membres de l'application.
          `,
          `
          Selon les demandes locales, les éleveurs et bergers peuvent aussi communiquer entre eux, en partageant leurs observations de terrain.
          `,
          `
          Dans la mesure du possible, selon les éléments factuels et indices fiables à disposition, <b>la probabilité d'un lien avec un ou des prédateurs est évaluée par nos agents</b>, en concertation avec le rapporteur de l'évènement (contributeur Proxiloup).
          `,
        ]}
        buttonText=""
        buttonUrl=""
      />

      <Section
        background="secondary"
        fluid={data.envoyer.childImageSharp.fluid}
        title="Signalement de proximité"
        texts={[
          `
          Pour les agents, c’est un outil qui leur permet en quelques clics, de <b>signaler une présence probable de prédateur</b> à tous les éleveurs situés à proximité en les sélectionnant automatiquement (rayon de 5, 10, 20... 50 Km) ou manuellement (traçage d’un polygone). 
          `,
          `
          Cette action peut se faire <b>directement sur le terrain</b> avec un smartphone sous réserve d’une connexion internet mobile, ou sur ordinateur une fois de retour au bureau.
          `,
        ]}
        buttonText=""
        buttonUrl=""
      />

      <Box paddingY={8} bgcolor="text.primary" color="secondary.contrastText">
        <Container maxWidth="lg">
          <Lightbox fluid={data.schema.childImageSharp.fluid} />
        </Container>
      </Box>

      <Section
        background="primary"
        fluid={data.initiative.childImageSharp.fluid}
        title="Initiative locale"
        texts={[
          `
          Proxiloup se déploie progressivement sur les territoires, découpés par canton pour la Suisse et par département pour la France. Si aucun <b>réseau d’alerte local</b> n’existe sur un territoire, il est possible de commencer le déploiement de Proxiloup au niveau d’une communauté de communes par exemple.
          `,
          `
          C’est le cas dans la vallée de la Thur dans le Haut-Rhin, où une <b>initiative locale</b> a permis la création d’un groupe de travail composés d’éleveurs qui bénéficient de l’application dès 2019.
          `,
        ]}
        buttonText="Article presse DNA"
        buttonUrl="https://www.dna.fr/economie/2019/09/05/il-faut-revenir-a-un-modele-qui-permette-de-vivre-avec-le-loup"
      />

      <Section
        background=""
        fluid={data.frontalier.childImageSharp.fluid}
        title="Projet transfrontalier"
        texts={[
          `
          Du fait de la mobilité des grands carnivores, Proxiloup est aussi conçu pour gérer l’information entre les <b>territoires mitoyens</b> (départements, cantons, pays...). Cette spécificité permet une meilleure <b>coordination transfrontalière</b> pour améliorer la protection des troupeaux situés en <b>limites territoriales</b>.
          `,
          `
          C’est le cas pour le Jura franco-suisse par exemple, où le Canton de Vaud a initié le projet Proxiloup en 2018 pour anticiper les problèmes potentiels liés à la colonisation des loups sur l'arc jurassien.
          `,
        ]}
        buttonText="Site de l'état de Vaud"
        buttonUrl="https://www.vd.ch/themes/environnement/biodiversite-et-paysage/dommages-causes-par-la-faune/#c2062848"
      />

      <Section
        background="secondary"
        fluid={data.formation.childImageSharp.fluid}
        title="Formation et sensibilisation"
        texts={[
          `
          Proxiloup s’inscrit dans un <b>concept global de protection</b> (projet Vigi.prédation) et les <b>réseaux d’éleveurs et bergers</b> qui le souhaitent peuvent prendre contact avec l’équipe de l'Institut IPRA (FJML) pour organiser des sessions de <b>formations</b>.
          `,
          `
          Ainsi, chaque participant acquiert la <b>connaissance scientifique</b> pour comprendre les <b>équilibres écologiques</b>, l'<b>éthologie des prédateurs</b>, et reçoit des <b>conseils techniques</b> pour gérer et améliorer sa protection.
          `,
          `
          Par la suite, l’application Proxiloup continue de transmettre des informations et devient une <b>plateforme ressource pour l’échange de connaissances</b>. 
          `,
        ]}
        buttonText="Organiser une formation"
        buttonUrl="https://www.ipra-landry.com/nos-prestations/"
      />
    </Fragment>
  )
}

export default IndexPage
